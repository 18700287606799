<template>
  <div class="mx-a">
    <cms-section v-if="eSpots?.[0]" :section="eSpots?.[0]" />
    <base-lazy-hydrate when="interaction">
      <section
        v-for="({ cols, tiles, title }, i) in topSections"
        :key="i"
        :aria-labelledby="`help-section-heading-${i}`"
        class="space-y-6"
        :class="{ 'mt-6': i }"
      >
        <component
          :is="i === 0 ? 'h1' : 'h2'"
          v-if="title"
          :id="`help-section-heading-${i}`"
          :class="brandClasses.sectionHeading"
        >
          {{ title }}
        </component>
        <base-grid :cols="cols" gap="sm">
          <vf-tile v-for="(tile, j) in tiles" :key="j" v-bind="tile" />
        </base-grid>
      </section>
    </base-lazy-hydrate>
    <vf-button v-if="middleCta" class="mx-a mt-6 flex <md:w-full" :to="middleCtaLink">
      {{ middleCta }}
    </vf-button>
    <section aria-labelledby="contact-section-heading" class="mt-10 space-y-6">
      <h2 id="contact-section-heading" :class="brandClasses.sectionHeading">
        {{ bottomTitle }}
      </h2>
      <div v-if="showEmployeeContactUs && auth.isEmployee" class="flex justify-center">
        <vf-tile
          v-bind="bottomTiles.employeeContact"
          style="max-width: 18rem"
        >
          <template #title>
            <span style="white-space: pre-line">
              {{ bottomTiles.employeeContact.title }}
            </span>
          </template>
        </vf-tile>
      </div>
      <div v-else class="flex gap-2 wrap lg:gap-3">
        <vf-tile
          v-show="$feature.enableChat"
          v-once
          v-bind="bottomTiles.chat"
          class="w-full md:flex-1"
          data-chat="help:widget"
          :disabled="!isCanada && !$chat.available"
          @click="$chat.open()"
        >
          <template #description>
            <cms-section v-if="eSpots?.[1] && $feature.enableContactUsESpots" :section="eSpots?.[1]" />
            <span v-else style="white-space: pre-line">
              {{ bottomTiles.chat.description }}
            </span>
          </template>
        </vf-tile>
        <vf-tile
          v-if="bottomTiles.email"
          v-bind="bottomTiles.email"
          class="w-full md:flex-1"
        >
          <template #description>
            <cms-section v-if="eSpots?.[2] && $feature.enableContactUsESpots" :section="eSpots?.[2]" />
            <span v-else style="white-space: pre-line">
              {{ bottomTiles.email.description }}
            </span>
          </template>
        </vf-tile>
        <base-lazy-hydrate when="visible" @hydrated="isPhoneHydrated = true">
          <vf-tile
            v-if="bottomTiles.phone"
            v-bind="bottomTiles.phone"
            class="w-full md:flex-1"
            :disabled="isPhoneHydrated && $viewport.lg"
            :to="bottomTiles.phone.link"
          >
            <template #description>
              <cms-section v-if="eSpots?.[3] && $feature.enableContactUsESpots" :section="eSpots?.[3]" />
              <span v-else style="white-space: pre-line">
                {{ bottomTiles.phone.description }}
              </span>
            </template>
          </vf-tile>
        </base-lazy-hydrate>
      </div>
    </section>
    <cms-section v-if="eSpots?.[4]" :section="eSpots?.[4]" />
  </div>
</template>

<script lang="ts" setup>
const eSpots = (await useCms().getSections.generic('help')).data.value?.content.sections
const { data, error } = await useApi().internal.getStaticPage('help')
if (!data.value) throw createError({ ...error.value!, fatal: true })
const { bottomTiles, bottomTitle, middleCta, topSections } = data.value

const { brandClasses, showEmployeeContactUs } = useAppConfig().pages.help
const auth = useAuthStore()
const isCanada = useCountryCode() === 'CA'

const isPhoneHydrated = shallowRef(false)
const middleCtaLink = ref('/help/contact-us')

onMounted(() => {
  if (isMobile()) middleCtaLink.value = '/help/all-topics'
})
</script>
